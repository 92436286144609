<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    scrollable
    max-width="700"
  >
    <v-card>
      <v-toolbar dark color="blue darken-2" class="elevation-0 px-3">
        <span class="text-h5 font-weight-bold white--text"
          >Información de responsable</span
        >
      </v-toolbar>
      <v-card-text class="mt-5">
        <!-- <v-container> -->
        <!-- general details -->
        <v-row>
          <v-sheet
            rounded="lg"
            color="white"
            elevation="0"
            max-width="600"
            class="mx-auto my-5 py-5 grey lighten-4"
          >
            <p class="text-h5 font-weight-bold text-center mb-8">
              Información general
            </p>
            <v-row class="text-left">
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                Nombres:
              </v-col>
              <v-col>{{ manager.firstName }}</v-col>

              <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                Apellidos:
              </v-col>
              <v-col>{{ manager.lastName }}</v-col>
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                DUI:
              </v-col>
              <v-col>{{ manager.dui }}</v-col>
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                NIT:
              </v-col>
              <v-col>
                {{ manager.nit ? manager.nit : "No especificado" }}
              </v-col>
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                Nacionalidad:
              </v-col>
              <v-col>
                {{
                  manager.Nationality
                    ? manager.Nationality.name
                    : "No especificado"
                }}
              </v-col>
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                Fecha de nacimiento:
              </v-col>
              <v-col>{{ manager.birthday }}</v-col>
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                Municipio en el que reside:
              </v-col>
              <v-col>
                {{
                  manager.Municipality
                    ? manager.Municipality.name
                    : "No especificado"
                }}
              </v-col>
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                Parentesco:
              </v-col>
              <v-col>
                {{ manager.Kinship ? manager.Kinship.name : "No especificado" }}
              </v-col>
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                Estado civil:
              </v-col>
              <v-col>
                {{
                  manager.MaritalStatus
                    ? manager.MaritalStatus.name
                    : "No especificado"
                }}
              </v-col>
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                Responsable legal:
              </v-col>
              <v-col>
                {{ manager.isManager ? "Si" : "No" }}
              </v-col>
            </v-row>
          </v-sheet>
        </v-row>
        <!-- contact info -->
        <v-row>
          <v-sheet
            rounded="lg"
            color="white"
            elevation="0"
            max-width="600"
            class="mx-auto my-5 py-5 grey lighten-4"
          >
            <p class="text-h5 font-weight-bold text-center mb-8">
              Información de contacto
            </p>

            <v-row class="text-left">
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                Teléfono celular:
              </v-col>
              <v-col>
                {{ manager.cellphone ? manager.cellphone : "No especificado" }}
              </v-col>
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                Teléfono residencial:
              </v-col>
              <v-col>
                {{ manager.phone ? manager.phone : "No especificado" }}
              </v-col>
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                Correo personal:
              </v-col>
              <v-col>
                {{ manager.email ? manager.email : "No especificado" }}
              </v-col>
            </v-row>
          </v-sheet>
        </v-row>
        <!-- workplace info -->
        <v-row>
          <v-sheet
            rounded="lg"
            color="white"
            elevation="0"
            max-width="600"
            class="mx-auto my-5 py-5 grey lighten-4"
          >
            <p class="text-h5 font-weight-bold text-center mb-8">
              Información laboral
            </p>

            <v-row class="text-left">
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                Profesión:
              </v-col>
              <v-col>
                {{
                  manager.profession ? manager.profession : "No especificado"
                }}
              </v-col>
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                Lugar de trabajo:
              </v-col>
              <v-col>
                {{ manager.workplace ? manager.workplace : "No especificado" }}
              </v-col>
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                Extensión del teléfono:
              </v-col>
              <v-col>
                {{ manager.extension ? manager.extension : "No especificado" }}
              </v-col>
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                Teléfono del lugar de trabajo:
              </v-col>
              <v-col>
                {{ manager.workPhone ? manager.workPhone : "No especificado" }}
              </v-col>
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                Dirección del lugar de trabajo:
              </v-col>
              <v-col>
                {{
                  manager.workAddress ? manager.workAddress : "No especificado"
                }}
              </v-col>
            </v-row>
          </v-sheet>
        </v-row>
        <!-- </v-container> -->
      </v-card-text>
      <v-divider></v-divider>
      <!-- actions -->
      <v-card-actions class="justify-end pt-0">
        <v-btn text outlined class="mr-1" @click="toggleModal">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ManagerInformationModal",
  props: {
    manager: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
  },
};
</script>
