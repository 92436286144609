<template>
  <div>
    <v-dialog
      v-model="dialogState"
      fullscreen
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="blue" extended extension-height="40">
          <v-btn icon dark @click="toggleModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Información del estudiante</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="toggleModal()">
              Cerrar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card
          class="pt-6 mx-auto my-3"
          flat
          max-width="960"
          style="width: 100%;"
        >
          <div style="width: 100%;" class="text-center">
            <v-avatar v-if="hasPhoto" size="150" class="mb-4">
              <v-img :src="`${student.academicLevelDetails?.photo}`"></v-img>
            </v-avatar>

            <div v-else class="mx-auto symbol symbol-130 mb-5">
              <div
                class="symbol-label display-3 font-weight-bold bg-blue text-black"
              >
                {{ getFirstNameInitial }}{{ getLastNameInitial }}
              </div>
            </div>

            <div class="blue--text text-body-1 font-weight-bold">
              {{ student.details?.code }}
            </div>
            <h3 class="text-h5 font-weight-bold">
              {{ student.details?.firstName }}
            </h3>
            <h3 class="text-h5 mb-2 font-weight-bold">
              {{ student.details?.lastName }}
            </h3>
            <div class="blue--text text-body-1 mb-2 font-weight-bold">
              {{ student.academicLevelDetails?.grade }}
              {{ student.academicLevelDetails?.section_group
              }}<span
                v-if="this.student.academicLevelDetails?.speciality_id < 8"
                >, {{ student.academicLevelDetails?.technical_group }}</span
              >
            </div>
            <div class="blue--text mb-2">
              {{ student.details?.email }}
            </div>
          </div>
        </v-card>
        <v-tabs background-color="white" color="blue" centered icons-and-text>
          <v-tabs-slider></v-tabs-slider>

          <v-tab>
            Información general
            <v-icon>mdi-account-circle</v-icon>
          </v-tab>

          <v-tab>
            Información médica
            <v-icon>mdi-medical-bag</v-icon>
          </v-tab>

          <v-tab>
            Información familiar
            <v-icon>mdi-human-male-female-child</v-icon>
          </v-tab>

          <v-tab>
            Sostenedor económico
            <v-icon>mdi-account-cash</v-icon>
          </v-tab>
          <!-- <v-tab>
            Información COVID-19
            <v-icon>mdi-needle</v-icon>
          </v-tab> -->
          <!-- begin::Student general information -->
          <v-tab-item>
            <!-- begin::Student personal information-->
            <div
              class="text-h6 black--text font-weight-medium text-center mt-7"
            >
              Información personal
            </div>
            <v-sheet
              rounded="xl"
              color="white"
              elevation="2"
              max-width="740"
              class="mx-auto my-5"
            >
              <v-row class="text-left" tag="div">
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Nombres:
                </v-col>
                <v-col>{{ student.details?.firstName }}</v-col>
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Apellidos:
                </v-col>
                <v-col>{{ student.details?.lastName }}</v-col>
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Fecha de nacimiento:
                </v-col>
                <v-col>{{ student.details?.birthday }}</v-col>
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Edad:
                </v-col>
                <v-col>{{ calculateAge(student.details?.birthday) }}</v-col>
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Género:
                </v-col>
                <v-col>{{ student.details?.Gender?.name }}</v-col>
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Nacionalidad:
                </v-col>
                <v-col>{{ student.details?.Nationality?.name }}</v-col>
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Deporte que más práctica:
                </v-col>
                <v-col>{{ student.details?.Sport?.name }}</v-col>
              </v-row>
            </v-sheet>
            <!-- end::Student personal information -->
            <!-- begin::Student contact information -->
            <div
              class="text-h6 black--text font-weight-medium text-center mt-7"
            >
              Información de contacto
            </div>
            <v-sheet
              rounded="xl"
              color="white"
              elevation="2"
              max-width="740"
              class="mx-auto my-5"
            >
              <v-row class="text-left" tag="div">
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Teléfono residencial:
                </v-col>
                <v-col>{{ student.details?.phone }}</v-col>
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Teléfono celular:
                </v-col>
                <v-col>{{ student.details?.cellphone }}</v-col>
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Correo personal:
                </v-col>
                <v-col>{{ student.details?.email }}</v-col>
              </v-row>
            </v-sheet>
            <!-- end::Student contact information -->
            <!-- begin::Student address information-->
            <div
              class="text-h6 black--text font-weight-medium text-center mt-7"
            >
              Información domiciliar
            </div>
            <v-sheet
              rounded="xl"
              color="white"
              elevation="2"
              max-width="740"
              class="mx-auto my-5"
            >
              <v-row class="text-left" tag="div">
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Dirección:
                </v-col>
                <v-col>{{ student.details?.address }}</v-col>
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Departamento:
                </v-col>
                <v-col>{{
                  student.details?.Municipality?.Department?.name
                }}</v-col>
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Municipio:
                </v-col>
                <v-col>{{ student.details?.Municipality?.name }}</v-col>
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Distancia entre domicilio e Instituto:
                </v-col>
                <v-col>{{ student.details?.distance }}</v-col>
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Medio de transporte que utiliza:
                </v-col>
                <v-col>{{ student.details?.Transport?.name }}</v-col>
              </v-row>
            </v-sheet>
            <!-- end::Student address information -->

            <!-- begin::Student religion information -->
            <div
              class="text-h6 black--text font-weight-medium text-center mt-7"
            >
              Información religiosa
            </div>
            <v-sheet
              rounded="xl"
              color="white"
              elevation="2"
              max-width="740"
              class="mx-auto my-5"
            >
              <v-row class="text-left" tag="div">
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Religión:
                </v-col>
                <v-col class="pa-0 mt-3">
                  <span
                    class="text-body-1 text-center my-3"
                    v-if="student.sacraments?.length == 0"
                    >No se registró una religión.</span
                  >
                  <span v-else>{{ studentReligion }}</span>
                </v-col>

                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Sacramentos:
                </v-col>
                <v-col class="p-0">
                  <span
                    class="text-body-1 text-center my-3"
                    v-if="student.sacraments?.length == 0"
                    >No se registró ningún sacramento.</span
                  >
                  <div>
                    <div
                      v-for="(sacrament, index) in student.sacraments"
                      :key="`${index}`"
                    >
                      <v-chip
                        v-if="sacrament.realized"
                        class="ma-2"
                        color="green"
                        dark
                      >
                        {{ sacrament.Sacrament?.name }}
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon class="ml-1" rigth v-bind="attrs" v-on="on">
                              mdi-check-circle
                            </v-icon>
                          </template>
                          <span>Recibir</span>
                        </v-tooltip>
                      </v-chip>
                      <v-chip v-else class="ma-2" color="red" dark outlined>
                        {{ sacrament.Sacrament?.name }}
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon class="ml-1" rigth v-bind="attrs" v-on="on">
                              mdi-close-circle
                            </v-icon>
                          </template>
                          <span>Sin recibir</span>
                        </v-tooltip>
                      </v-chip>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-sheet>
            <!-- end::Student religion information -->
          </v-tab-item>
          <!-- end::Student general information -->

          <!-- begin::Student medic information -->
          <v-tab-item>
            <!-- begin::Student deseases -->
            <div
              class="text-h6 black--text font-weight-medium text-center mt-7"
            >
              Enfermedades que padece
            </div>
            <v-sheet
              rounded="xl"
              color="white"
              elevation="2"
              max-width="740"
              class="mx-auto my-5"
            >
              <v-row class="text-left" tag="div">
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Enfermedades ó condiciones:
                </v-col>
                <v-col>
                  <span
                    class="text-body-1 text-center my-3"
                    v-if="student.diseases?.length == 0"
                    >No padece ninguna enfermedad.</span
                  >
                  <div
                    v-else
                    v-for="(disease, index) in student.diseases"
                    :key="index"
                  >
                    <v-chip class="ma-2" color="yellow darken-4" dark>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mr-1" rigth v-bind="attrs" v-on="on">
                            mdi-alert-circle-outline
                          </v-icon>
                        </template>
                        <span>Sí padece</span>
                      </v-tooltip>
                      {{ disease.Disease?.name }}
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
            </v-sheet>
            <!-- end::Student deseases -->

            <!-- begin::Student medications -->
            <div
              class="text-h6 black--text font-weight-medium text-center mt-7"
            >
              Información acerca de medicación
            </div>
            <v-sheet
              rounded="xl"
              color="white"
              elevation="2"
              max-width="740"
              class="mx-auto my-5"
            >
              <v-row class="text-left" tag="div">
                <v-col class="text-right mb-2" tag="strong" cols="6">
                  Medicamentos de caracter permanente:
                </v-col>
                <v-col class="pt-0 pl-0" cols="6">
                  <span
                    class="text-body-1 text-center my-3"
                    v-if="student.prescribedMedicines?.length == 0"
                    >No hay ningún medicamento de caracter permanente.</span
                  >
                  <v-list-item
                    v-for="(medicine, index) in student.prescribedMedicines"
                    :key="`${index}`"
                    class="p-0"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">
                        <v-icon>
                          mdi-circle-small
                        </v-icon>
                        {{ medicine.Medicine?.name }}
                      </v-list-item-title>
                      <p class="mb-0 pl-7">Dosis: {{ medicine.dose }}</p>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Medicamentos a los que es alérgico:
                </v-col>
                <v-col class="pt-0 pl-0">
                  <span
                    class="text-body-1 text-center my-3"
                    v-if="student.allergicMedicines?.length == 0"
                    >No hay ningún medicamento al que sea alérgico.</span
                  >
                  <v-list-item
                    v-for="(medicine, index) in student.allergicMedicines"
                    :key="`${index}`"
                    class="p-0"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon>
                          mdi-circle-small
                        </v-icon>
                        {{ medicine.Medicine?.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-sheet>
            <div
              class="text-h6 black--text font-weight-medium text-center mt-7"
            >
              Más información médica
            </div>
            <v-sheet
              rounded="xl"
              color="white"
              elevation="2"
              max-width="740"
              class="mx-auto my-5"
            >
              <v-row tag="div" class="px-10 m-0">
                <v-col>
                  <p>
                    {{
                      student.details?.medicalInformation ||
                        "No posee ninguna descripción extra."
                    }}
                  </p>
                </v-col>
              </v-row>
            </v-sheet>
            <!-- end::Student medications -->
          </v-tab-item>
          <!-- end::Student medic information -->

          <!-- begin::Student familiar information -->
          <v-tab-item>
            <!-- begin::Student familiar information-->
            <div
              class="text-h6 black--text font-weight-medium text-center mt-7"
            >
              Información general de la familia
            </div>
            <v-sheet
              rounded="xl"
              color="white"
              elevation="2"
              max-width="740"
              class="mx-auto my-5"
            >
              <v-row class="text-left" tag="div">
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Miembros que conforman la familia:
                </v-col>
                <v-col>{{
                  student.familyDetails?.numberMembers || "Sin especificar"
                }}</v-col>
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Convivencia:
                </v-col>
                <v-col>{{
                  student.familyDetails?.Coexistence?.name || "Sin especificar"
                }}</v-col>
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Reponsable económico:
                </v-col>
                <v-col>{{
                  student.familyDetails?.EconomicManager?.name ||
                    "Sin especificar"
                }}</v-col>
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Situación de los padres biológicos:
                </v-col>
                <v-col>{{
                  student.familyDetails?.ParentingSituation?.name ||
                    "Sin especificar"
                }}</v-col>
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Rango salarial:
                </v-col>
                <v-col>{{
                  student.familyDetails?.SalaryRange?.name || "Sin especificar"
                }}</v-col>
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Tipo de vivienda:
                </v-col>
                <v-col>{{
                  student.familyDetails?.HousingType?.name || "Sin especificar"
                }}</v-col>
              </v-row>
            </v-sheet>
            <!-- end::Student familiar information -->
            <!-- begin::Student managers-->
            <div
              class="text-h6 black--text font-weight-medium text-center mt-7"
            >
              Listado de los responsables del estudiante
            </div>
            <v-sheet
              rounded="xl"
              color="white"
              elevation="2"
              max-width="740"
              class="mx-auto my-5"
            >
              <v-row>
                <v-col class="mx-7">
                  <v-list-item-group color="blue">
                    <v-list-item
                      v-for="(manager, index) in student.managers"
                      :key="`${index}`"
                    >
                      <v-list-item-avatar>
                        <v-avatar color="blue" size="56" class="white--text">
                          <v-icon color="white">
                            mdi-account
                          </v-icon>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="text-body-1">
                          {{ `${manager.firstName} ${manager.lastName}` }}
                          <span
                            class="text-body-2 font-weight-medium"
                            v-if="manager.isManager"
                          >
                            Responsable
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-btn
                          depressed
                          small
                          @click="
                            selectManager(manager);
                            toggleManagerModal();
                          "
                        >
                          Ver
                          <v-icon color="blue" right>
                            mdi-eye-outline
                          </v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-col>
              </v-row>
            </v-sheet>
            <!-- end::Student managers -->

            <!-- AQUI VA LA INFO DE LOS RELATIVES -->
            <!-- begin::Student brother's and sister's -->
            <div
              class="text-h6 black--text font-weight-medium text-center mt-7"
            >
              Listado de hermanos/as que estan estudiando actualmente en la
              institución
            </div>
            <v-sheet
              rounded="xl"
              color="white"
              elevation="0"
              max-width="740"
              class="mx-auto my-5 d-flex flex-row flex-wrap justify-start align-start"
            >
              <span
                class="text-body-1 text-center pl-3 my-3"
                v-if="student.relativesActive?.length == 0"
                >No hay ningún hermano/a registrado.</span
              >
              <v-card
                max-width="275"
                width="275"
                class="mb-3 mr-3"
                v-for="(relative, index) in student.relativesActive"
                :key="`${index}`"
              >
                <div>
                  <div>{{ `${relative.code}` }}</div>
                  <p class="text-h5 text--primary mb-2">
                    {{ relative.name }}
                  </p>
                </div>
              </v-card>
            </v-sheet>
            <!-- end::Student brother's and sister's  -->
            <!-- begin::Student relatives that have studied in the institution -->
            <div
              class="text-h6 black--text font-weight-medium text-center mt-7"
            >
              Listado de familiares que han estudiando en la institución
            </div>
            <v-sheet
              rounded="xl"
              color="white"
              elevation="0"
              max-width="740"
              class="mx-auto my-5 d-flex flex-row flex-wrap justify-start align-start"
            >
              <span
                class="text-body-1 text-center pl-3 my-3"
                v-if="student.relativesGraduated?.length == 0"
                >No hay ningún familiar registrado.</span
              >
              <v-card
                max-width="275"
                width="275"
                class="mb-3 mr-3"
                v-for="(relative, index) in student.relativesGraduated"
                :key="`${index}`"
              >
                <div>
                  <p class="text-h5 text--primary mb-2">
                    {{ relative.name }}
                  </p>
                  <p class="mb-2">{{ relative.Kinship?.name }}</p>

                  <div class="text--primary">
                    Salió el
                    <span class="font-weight-medium">{{
                      relative.graduationYear
                    }}</span>
                    de
                    <span class="font-weight-medium">{{
                      relative.grade?.name
                    }}</span>
                  </div>
                </div>
              </v-card>
            </v-sheet>
            <!-- end::Student relatives that have studied in the institution  -->
          </v-tab-item>
           <!-- begin::Student familiar information -->

          <!--begin::Economic manager information-->
          <v-tab-item>
            <div
              class="text-h6 black--text font-weight-medium text-center mt-7"
            >
              Información del sostenedor económico
            </div>
            <v-sheet
              rounded="xl"
              color="white"
              elevation="2"
              max-width="740"
              class="mx-auto my-5"
            >
              <v-row class="text-left" tag="div">
                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Nombres:
                </v-col>
                <v-col>{{ student.economicManager?.first_name || "Sin especificar" }}</v-col>

                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Apellidos:
                </v-col>
                <v-col>{{  student.economicManager?.last_name || "Sin especificar" }}</v-col>

                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Correo electrónico:
                </v-col>
                <v-col>{{  student.economicManager?.email || "Sin especificar" }}</v-col>

                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Número de teléfono:
                </v-col>
                <v-col>{{  student.economicManager?.cellphone || "Sin especificar" }}</v-col>

                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Departamento:
                </v-col>
                <v-col>{{ getEconomicManagerDepartment }}</v-col>

                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Municipio:
                </v-col>
                <v-col>{{ getEconomicManagerMunicipality }}</v-col>

                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  Dirección:
                </v-col>
                <v-col>{{  student.economicManager?.address || "Sin especificar" }}</v-col>

                <v-col class="text-right mr-4 mb-2" tag="strong" cols="6">
                  DUI:
                </v-col>
                <v-col>{{  student.economicManager?.dui || "Sin especificar" }}</v-col>
                
              </v-row>
            </v-sheet>
          </v-tab-item>
          <!--end::Economic manager information-->
         
        </v-tabs>
      </v-card>
    </v-dialog>

    <ManagerInformationModal
      ref="mdlManager"
      :manager="selectedManager"
    ></ManagerInformationModal>
  </div>
</template>

<script>
import ManagerInformationModal from "@/components/modals/students/ManagerInformationModal.vue";

export default {
  name: "StudentInformationModal",
  components: {
    ManagerInformationModal,
  },
  props: {
    student: {
      type: Object,
    },
  },
  data() {
    return {
      dialogState: false,
      selectedManager: {},
    };
  },
  methods: {
    toggleModal() {
      if (this.$route.query.uuid) {
        this.$router.push({ path: "students" });
      }
      this.dialogState = !this.dialogState;
    },

    selectManager(manager) {
      this.selectedManager = { ...manager };
    },

    toggleManagerModal() {
      this.$refs.mdlManager.toggleModal();
    },
  },
  computed: {
    hasPhoto() {
      return !!this.student.academicLevelDetails?.photo;
    },
    hasSacraments() {
      return !!this.student.sacraments?.length;
    },

    studentReligion() {
      if (this.student.sacraments?.length > 0) {
        return this.student.sacraments[0]?.Sacrament?.Religion?.name;
      }

      return null;
    },

    getFirstNameInitial() {
      return this.student.details?.firstName?.charAt(0)?.toUpperCase();
    },
    getLastNameInitial() {
      return this.student.details?.lastName?.charAt(0)?.toUpperCase();
    },
    getEconomicManagerDepartment() {
      if (typeof(this.student.economicManage) == Object) {
        return JSON.parse(this.student.economicManager.municipality).departamento.nombre
      }
      return 'Sin especificar'
    },
    getEconomicManagerMunicipality() {
      if (typeof(this.student.economicManage) == Object)  {
        return JSON.parse(this.student.economicManager.municipality).nombre
      }
      return 'Sin especificar'
    }
  },
};
</script>
