<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark class="elevation-0" color="indigo lighten-4">
        <v-btn class="red lighten-4" @click="closedFromToolbar()">
          <v-icon color="red" left>mdi-close</v-icon>
          <span class="red--text font-weight-bold">Cerrar</span>
        </v-btn>
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <p
              class="font-weight-bold text-center text-h5 mb-0 d-none d-sm-inline indigo--text"
            >
              Formulario para confirmación de sostenedor económico, facturación
              electrónica, firma de contrato y pagaré
            </p>
            <p
              class="font-weight-bold text-center text-h5 mb-0 d-sm-none indigo--text"
            >
              Formulario de facturación y representante
            </p>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-container>
        <v-row class="mt-2 d-flex justify-center">
          <v-col cols="12" sm="8" md="12">
            <v-alert prominent dense text type="warning" class="rounded-lg">
              Los datos solicitados en este formulario serán usados para
              confirmación de sostenedor económico , facturación electrónica,
              firma de contrato y pagaré.
            </v-alert>
          </v-col>
        </v-row>

        <!-- Here's where the form starts -->
        <v-row class="d-flex justify-center justify-md-start">
          <v-col cols="12" sm="8" md="6" lg="4" xl="3">
            <form-group
              :validator="$v.studentEconomicManager.first_name"
              name="Nombre"
            >
              <v-text-field
                slot-scope="{ attrs }"
                v-model.trim="studentEconomicManager.first_name"
                filled
                label="Nombre"
                rounded
                v-bind="attrs"
                @input="$v.studentEconomicManager.first_name.$touch()"
              ></v-text-field>
            </form-group>
          </v-col>
          <v-col cols="12" sm="8" md="6" lg="4" xl="3">
            <form-group
              :validator="$v.studentEconomicManager.last_name"
              name="Apellido"
            >
              <v-text-field
                slot-scope="{ attrs }"
                v-model.trim="studentEconomicManager.last_name"
                filled
                label="Apellido"
                rounded
                v-bind="attrs"
                @input="$v.studentEconomicManager.last_name.$touch()"
              ></v-text-field>
            </form-group>
          </v-col>

          <v-col cols="12" sm="8" md="6" lg="4" xl="3">
            <form-group
              :validator="$v.studentEconomicManager.email"
              name="de correo electrónico"
            >
              <v-text-field
                slot-scope="{ attrs }"
                v-model.trim="studentEconomicManager.email"
                filled
                label="Correo electrónico"
                rounded
                v-bind="attrs"
                @input="$v.studentEconomicManager.email.$touch()"
              ></v-text-field>
            </form-group>
          </v-col>

          <v-col cols="12" sm="8" md="6" lg="4" xl="3">
            <form-group
              :validator="$v.studentEconomicManager.cellphone"
              name="Número de teléfono"
            >
              <v-text-field
                slot-scope="{ attrs }"
                v-model.trim="studentEconomicManager.cellphone"
                :error-messages="phoneErrors"
                filled
                label="Número de teléfono"
                rounded
                v-bind="attrs"
                @input="$v.studentEconomicManager.cellphone.$touch()"
              ></v-text-field>
            </form-group>
          </v-col>
          <v-col cols="12" sm="8" md="6" lg="4" xl="3">
            <form-group
              name="Nacionalidad"
              :validator="$v.studentEconomicManager.nationality"
            >
              <v-select
                slot-scope="{ attrs }"
                v-bind="attrs"
                filled
                rounded
                :items="nationalitiesList"
                item-value="name"
                item-text="name"
                label="Nacionalidad"
                v-model="studentEconomicManager.nationality"
              ></v-select>
            </form-group>
          </v-col>
          <v-col cols="12" sm="8" md="6" lg="4" xl="3">
            <form-group name="DUI" :validator="$v.studentEconomicManager.dui">
              <v-text-field
                slot-scope="{ attrs }"
                v-mask="
                  studentEconomicManager.nationality === 'Salvadoreño/a'
                    ? '########-#'
                    : ''
                "
                v-model.trim="studentEconomicManager.dui"
                :error-messages="duiErrors"
                filled
                :label="studentEconomicManager.nationality === 'Salvadoreño/a'
                    ? 'DUI'
                    : 'Número de documento de identidad'"
                rounded
                v-bind="attrs"
                @input="$v.studentEconomicManager.dui.$touch()"
              ></v-text-field>
            </form-group>
          </v-col>
          <v-col cols="12" sm="8" md="6" lg="4" xl="3">
            <form-group name="Departamento" :validator="$v.selectedDepartment">
              <v-select
                slot-scope="{ attrs }"
                v-model="selectedDepartment"
                :disabled="loadingDepartments"
                :items="departmentsList"
                :loading="loadingDepartments"
                filled
                item-text="name"
                item-value="id"
                label="Departamento"
                return-object
                rounded
                v-bind="attrs"
                @input="$v.selectedDepartment.$touch()"
              ></v-select>
            </form-group>
          </v-col>
          <v-col cols="12" sm="8" md="6" lg="4" xl="3">
            <form-group name="Municipio" :validator="$v.selectedMunicipality">
              <v-select
                slot-scope="{ attrs }"
                v-model="selectedMunicipality"
                :disabled="
                  loadingMunicipalities ||
                  Object.keys(selectedDepartment).length === 0
                "
                :items="municipalitiesList"
                :loading="loadingMunicipalities"
                filled
                item-text="name"
                item-value="id"
                label="Municipio"
                return-object
                rounded
                v-bind="attrs"
                @input="$v.selectedMunicipality.$touch()"
              ></v-select>
            </form-group>
          </v-col>
          <v-col cols="12" sm="8" md="12">
            <form-group
              :validator="$v.studentEconomicManager.address"
              name="dirección"
            >
              <v-textarea
                slot-scope="{ attrs }"
                v-model.trim="studentEconomicManager.address"
                auto-grow
                clearable
                counter
                filled
                label="Dirección"
                rounded
                rows="2"
                v-bind="attrs"
                @blur="
                  () =>
                    $v.studentEconomicManager.address &&
                    $v.studentEconomicManager.address.$touch()
                "
                @input="$v.studentEconomicManager.address.$touch()"
                @paste="handleEmptySpaces($event, 'address')"
                @keyup.enter="handleKeyUpAddress()"
              ></v-textarea>
            </form-group>
          </v-col>

          <v-col cols="12" class="d-flex justify-center pt-0" v-if="mode">
            <v-checkbox
              v-model="terms_and_condition"
              :disabled="stepsCompleted"
              :error-messages="termsConditionsErrors"
              label="El solicitante aceptó los términos y condiciones."
            ></v-checkbox>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="stepsCompleted"
                  class="ml-5 indigo"
                  fab
                  v-bind="attrs"
                  @click="showTermsAndConditions()"
                  v-on="on"
                >
                  <v-icon color="white">mdi-file-sign</v-icon>
                </v-btn>
              </template>
              <span>Términos y condiciones</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row class="mb-5">
          <v-col cols="12" class="d-flex justify-center" v-if="mode">
            <v-btn
              :disabled="isSavingEconomicManager || stepsCompleted"
              :loading="isSavingEconomicManager"
              color="indigo rounded-xl"
              depressed
              large
              outlined
              @click="saveStudentEconomicManager()"
            >
              Registrar responsable
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" class="d-flex justify-center" v-else>
            <v-btn
              :disabled="isSavingEconomicManager || stepsCompleted"
              :loading="isSavingEconomicManager"
              color="indigo rounded-xl"
              depressed
              large
              outlined
              @click="updateEconomicManager()"
            >
              Actualizar responsable
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <EconomicManagerTermsAndConditions
      ref="EconomicManagerTermsAndConditions"
      :dui="studentEconomicManager.dui"
      :lastname="studentEconomicManager.last_name"
      :name="studentEconomicManager.first_name"
    ></EconomicManagerTermsAndConditions>
  </v-dialog>
</template>

<script>
import {
  required,
  email,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
import {
  registerStudentEconomicManager,
  updateStudentEconomicManager,
} from "@/repositories/economicManagerRepository";

import EconomicManagerTermsAndConditions from "@/components/modals/students/EconomicManagerTermsAndConditions";

import { mapGetters } from "vuex";

export default {
  name: "registerStudentEconomicManagerModal",

  mounted() {
    this.getDepartments();
  },

  components: {
    EconomicManagerTermsAndConditions,
  },

  props: {
    economicManagerInfo: {
      type: Object,
    },
    refreshStudentManager: {
      type: Function,
    },
    studentInformation: {
      type: Object,
    },
  },

  data() {
    return {
      dialog: false,
      mode: true,
      isSavingEconomicManager: false,

      loadingMunicipalities: false,
      loadingDepartments: false,

      departmentsList: [],
      municipalitiesList: [],

      selectedDepartment: {},
      selectedMunicipality: {},

      nationalitiesList: [
        { id: 1, name: "Salvadoreño/a" },
        { id: 2, name: "Extranjero/a" },
      ],

      studentEconomicManager: {
        uuid: null,
        first_name: null,
        last_name: null,
        address: null,
        municipality: null,
        dui: null,
        nationality: "Salvadoreño/a",
        cellphone: null,
        email: null,
      },
      terms_and_condition: false,
    };
  },

  methods: {
    async refreshStudentEconomicInfo() {
      if (this.refreshStudentManager != null) {
        await this.refreshStudentManager(this.studentEconomicManager.uuid);
      }
    },
    saveStudentEconomicManager() {
      let formattedMunicipality = {
        id: this.selectedMunicipality.id,
        idDepartamento: this.selectedDepartment.id,
        nombre: this.selectedMunicipality.name,
        departamento: {
          id: this.selectedDepartment.id,
          nombre: this.selectedDepartment.name,
          idPais: 68,
          iso31662: "null",
        },
      };

      this.studentEconomicManager.municipality = JSON.stringify(
        formattedMunicipality
      );

      this.studentEconomicManager.uuid = this.studentInformation.uuid;

      //checking validations
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isSavingEconomicManager = true;

        registerStudentEconomicManager(this.studentEconomicManager)
          .then(async () => {
            this.fireToast({
              icon: "success",
              title: "Sostenedor económico registrado correctamente",
            });
            this.toggleModal();
            await this.refreshStudentEconomicInfo();
            this.$emit("registeredEconomicManager");
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "Hubo un problema, por favor intentar más tarde",
            });
          })
          .finally(() => {
            this.isSavingEconomicManager = false;
          });
      } else {
        this.fireToast({
          icon: "error",
          title: "Por favor, revise los datos ingresados",
        });
      }
    },

    updateEconomicManager() {
      // Format municipality

      let formattedMunicipality = {};

      const selectedMunicipalityName =
        this.selectedMunicipality?.nombre ?? this.selectedMunicipality?.name;
      const selectedDepartmentName =
        this.selectedDepartment?.nombre ?? this.selectedDepartment?.name;

      formattedMunicipality = {
        id: this.selectedMunicipality.id,
        idDepartamento: this.selectedDepartment.id,
        nombre: selectedMunicipalityName,
        departamento: {
          id: this.selectedDepartment.id,
          nombre: selectedDepartmentName,
          idPais: 68,
          iso31662: "null",
        },
      };

      this.studentEconomicManager.municipality = JSON.stringify(
        formattedMunicipality
      );

      this.studentEconomicManager.uuid = this.studentInformation.uuid;

      //checking validations
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isSavingEconomicManager = true;

        updateStudentEconomicManager(
          this.objectToFormData(this.studentEconomicManager)
        )
          .then(async () => {
            this.fireToast({
              icon: "success",
              title: "Sostenedor económico actualizado correctamente",
            });
            this.toggleModal();
            this.$emit("registeredEconomicManager");
            await this.refreshStudentEconomicInfo();
            this.resetValidations();
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "Hubo un problema, por favor intentar más tarde",
            });
          })
          .finally(() => {
            this.isSavingEconomicManager = false;
          });
      } else {
        this.fireToast({
          icon: "error",
          title: "Por favor, revise los datos ingresados",
        });
      }
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    closedFromToolbar() {
      this.dialog = !this.dialog;
      this.resetValidations();
    },

    handleEmptySpaces(event, fieldName) {
      event.preventDefault();
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      paste = paste.replace(/ {3,}/g, " ");
      this.studentEconomicManager[fieldName] =
        this.studentEconomicManager[fieldName] + paste;
    },

    handleKeyUpAddress() {
      this.$v.studentEconomicManager.address.$touch();
      if (!this.$v.studentEconomicManager.address.$invalid) {
        this.verticalFormStep = 3;
      }
    },

    async getDepartments() {
      this.loadingDepartments = true;
      let errors = null;

      try {
        let response = await fetch(
          "https://apiform.ricaldone.edu.sv/api/departments"
        );

        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }

        this.departmentsList = await response.json();
      } catch (error) {
        errors = error.message;
        console.error(errors);
      } finally {
        this.loadingDepartments = false;
      }
    },

    async getMunicipalities(departmentId) {
      this.loadingMunicipalities = true;
      this.municipalitiesList = [];
      let errors = null;

      try {
        let response = await fetch(
          `https://apiform.ricaldone.edu.sv/api/municipalities/department/${departmentId}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }

        this.municipalitiesList = await response.json();
      } catch (error) {
        errors = error.message;
        console.error(errors);
      } finally {
        this.loadingMunicipalities = false;
      }
    },
    resetValidations() {
      this.$v.$reset();
    },
    showTermsAndConditions() {
      this.$refs.EconomicManagerTermsAndConditions.toggleModal();
    },
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    duiErrors() {
      const errors = [];
      if (!this.$v.studentEconomicManager.dui.$dirty) return errors;
      !this.$v.studentEconomicManager.dui.required &&
        errors.push("El campo DUI es requerido.");
      return errors;
    },

    phoneErrors() {
      const errors = [];
      if (!this.$v.studentEconomicManager.cellphone.$dirty) return errors;
      !this.$v.studentEconomicManager.cellphone.required &&
        errors.push("El campo teléfono es requerido.");
      !this.$v.studentEconomicManager.cellphone.validPhoneNumberFormat &&
        errors.push("No debe contener guión, debe empezar con 2, 6 o 7");
      return errors;
    },

    termsConditionsErrors() {
      const errors = [];

      if (!this.$v.terms_and_condition.$dirty) return errors;
      !this.$v.terms_and_condition.validateTerms &&
        errors.push(
          "Es necesario que el solicitante acepte los términos y condiciones."
        );
      return errors;
    },

    stepsCompleted() {
      return (
        this.$v.studentEconomicManager.$invalid ||
        this.$v.selectedMunicipality.$invalid ||
        this.$v.selectedDepartment.$invalid
      );
    },
  },
  watch: {
    economicManagerInfo(newEconomicManager) {
      this.studentEconomicManager.uuid = newEconomicManager.uuid;
      if (Object.keys(newEconomicManager).length <= 1) {
        this.mode = true;
        return;
      }
      let municipality = newEconomicManager.municipality;
      if (typeof municipality === String) {
        municipality = JSON.parse(municipality);
      }

      this.studentEconomicManager.address = newEconomicManager.address;
      this.studentEconomicManager.cellphone = newEconomicManager.cellphone;
      this.studentEconomicManager.dui = newEconomicManager.dui;
      this.studentEconomicManager.email = newEconomicManager.email;
      this.studentEconomicManager.first_name = newEconomicManager.first_name;
      this.studentEconomicManager.last_name = newEconomicManager.last_name;
      this.studentEconomicManager.uuid = newEconomicManager.uuid;
      this.terms_and_condition =
        newEconomicManager.accepted_terms_and_conditions;

      //this.dui_images.front_url = newEconomicManager.dui_front_photo;
      //this.dui_images.back_url = newEconomicManager.dui_back_photo;

      this.selectedDepartment = municipality.departamento;

      // delete municipality.departamento;
      this.selectedMunicipality = municipality;

      // this.selectedMunicipality.name = municipality?.nombre
      // this.selectedDepartment.name = municipality?.departamento?.nombre
      //
      // delete this.selectedMunicipality.nombre
      // delete this.selectedDepartment.nombre

      // Set int update mode
      this.mode = false;

      this.$v.$touch();
    },
    selectedDepartment() {
      this.getMunicipalities(this.selectedDepartment.id);
    },
  },

  validations: {
    selectedMunicipality: {
      required,
    },
    selectedDepartment: {
      required,
    },
    terms_and_condition: {
      validateTerms() {
        return this.terms_and_condition || !this.mode;
      },
    },
    studentEconomicManager: {
      first_name: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(2),
      },
      last_name: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(2),
      },
      nationality: {
        required,
      },
      dui: {
        required,
      },
      cellphone: {
        required,
        validPhoneNumberFormat(value) {
          const pattern = /^(2|6|7)\d{3}\d{4}$/;
          return pattern.test(value);
        },
      },
      email: {
        required,
        email,
      },
      address: {
        required,
        maxLength: maxLength(240),
        minLength: minLength(10),
      },
    },
  },
};
</script>
