<template>
  <v-dialog v-model="show" width="500">
    <v-card>
      <v-card-title class="indigo lighten-4 d-flex justify-center align-center">
        <p class="text-h5 font-weight-bold text-center py-3 ma-0">
          Términos y condiciones
        </p>
      </v-card-title>
      <v-card-text class="px-8 py-5">
        <p class="text-justify text-body-1">
          Yo,
          <span class="font-weight-bold">{{ `${name} ${lastname}` }} </span>
          con Documento de Identidad número
          <span class="font-weight-bold">{{ dui }}</span>
          declaro bajo juramento que la información proporcionada en la calidad
          en que actúo en el presente Formulario es veráz y demostrable a efecto
          de cumplir lo dispuesto en la Ley tributaria. Me comprometo a informar
          a la Asociación Institución Salesiana - Instituto Técnico Ricaldone,
          de cualquier cambio relativo a la misma en caso de que se presente o
          sea necesario adicionar información. Eximo de responsabilidad a los
          miembros de Junta Directiva, Asociados, directores, gerentes, jefes,
          supervisores y otro personal administrativo de servicios operativos
          con la Asociación Institución Salesiana - Instituto Técnico Ricaldone
          de toda responsabilidad, inclusive a terceros, si esta declaración
          fuese falsa o errónea.
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EconomicManagerTermsAndConditions",
  data: () => ({
    show: false,
  }),
  props: {
    name: {
      type: String,
    },
    lastname: {
      type: String,
    },
    dui: {
      type: String,
    },
  },
  methods: {
    toggleModal() {
      this.show = !this.show;
    },
  },
};
</script>