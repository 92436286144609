import { studentService } from "@/core/services/studentApi.service"

export const registerStudentEconomicManager = (payload) => {
    return studentService.post(`economic-managers`, payload);
};

export const getStudentEconomicManager = (studentUuid) => {
    return studentService.get(`/economic-managers/${studentUuid}`)
}
export const updateStudentEconomicManager = (payload) => {
    return studentService.post('/economic-managers/update/economic-manager', payload);
}